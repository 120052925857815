import { useStore } from 'app/store';
import { LayoutCommon, defaultTheme } from 'libs/layout';
import { Main } from './layout-base.styled';

export type ILayoutBase = {
  children: JSX.Element | JSX.Element[];
  theme?: any;
};

export function LayoutBase(props: ILayoutBase) {
  const { children, theme } = props;
  const loading = useStore((state) => state.loading);

  return (
    <LayoutCommon theme={theme ?? defaultTheme} loading={loading}>
      <Main>{children}</Main>
    </LayoutCommon>
  );
}
