export const meMock = {
  id: 1,
  role: 'admin',
  is_active: true,
  login: 'sms_admin',
  email: 'ivanperovsky@gmail.com',
  phone: '79312972206',
  first_name: 'Ivan',
  last_name: 'Perovskii',
};
