/**
 * Message service
 *
 * @summary implements API calls
 */
import { api } from 'api';
import { notify } from 'libs/common';

/**
 * get messages
 * @param data
 */
export const getMessages = async (from: number, limit: number, order: string, filter?: Record<string, any>) => {
  let params = { offset: from, limit, ordering: order };
  let err = false;
  // console.log('🚀 ~ getMessages ~ params:', params);
  if (filter) {
    params = { ...params, ...filter };
  }
  const res = await api.get('api/messages', params).catch((error) => {
    err = true;
    notify('Получение списка сообщений', error.toString());
  });

  if (!res || err) {
    return { items: [], count: 0, counts: {} };
  }

  const { results, count, ...rest } = res;
  rest.next && delete rest.next;
  rest.previous && delete rest.previous;
  rest.count = count;

  //res.splice(20, 700);
  return { items: results, count: count, counts: rest };
};

/**
 * download messages
 * @param data
 */
export const downloadMessages = async (filter?: Record<string, any>, orderString?: string) => {
  let err = false;
  let params: Record<string, any> = {};
  if (filter) {
    params = { ...params, ...filter };
  }
  if (orderString) {
    params.ordering = orderString;
  }
  const res = await api.get('api/messages/load', params).catch((error) => {
    // console.log('🚀 ~ downloadMessages ~ error:', error);
    err = true;
    notify('Скачать CSV', error.toString());
  });

  if (err || !res.report_id) {
    return '';
  }
  //res.splice(20, 700);
  return res.report_id;
};

/**
 * get messages
 * @param data
 */
export const getMessagesConfig = async () => {
  let err = false;
  const res = await api.get('api/messages/config').catch((error) => {
    err = true;
    notify('Получение конфига сообщений', error.toString());
  });
  return res;
};

/**
 * check report status
 * @param reportId
 * @returns
 */
export const checkReport = async (reportId: any) => {
  let err = false;
  let errString = '';
  const res = await api.get('api/messages/reports?report_id=' + reportId).catch((error) => {
    err = true;
    errString = error.toString();
    notify('Проверка статуса загрузки списка сообщений', errString);
  });

  if (err) {
    throw new Error(errString);
  }
  return res;
};

export const getChartData = async (filter: any) => {
  let err = false;
  let errString = '';
  const res = await api.get('api/messages/chart', filter).catch((error) => {
    err = true;
    errString = error.toString();
    notify('Получение данных чарта', errString);
  });

  if (err) {
    throw new Error(errString);
  }
  return res;

}
