import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export type ModalProps = DialogProps & {
  open: boolean;
  title: string;
  close(): void;
};

export function Modal(props: ModalProps) {
  const { open, title, close, children, ...rest } = props;

  const handleEntering = () => {};

  const handleClose = () => {
    close();
  };

  return (
    <Dialog
      //sx={{ '& .MuiDialog-paper': { width: '95%', maxHeight: 635 } }}
      //maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      onClose={handleClose}
      // disablePortal={false}
      {...rest}
    >
      <DialogTitle>
        {title}
        <IconButton className="close" aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {children && <DialogContent dividers>{children}</DialogContent>}
    </Dialog>
  );
}

export default Modal;
