export const chartMock = [
  ['2023-07-01', 19462],
  ['2023-07-02', 16783],
  ['2023-07-03', 19062],
  ['2023-07-04', 19201],
  ['2023-07-05', 20882],
  ['2023-07-06', 18259],
  ['2023-07-07', 16572],
  ['2023-07-08', 18374],
  ['2023-07-09', 14722],
  ['2023-07-10', 17213],
  ['2023-07-11', 17248],
  ['2023-07-12', 18418],
  ['2023-07-13', 17127],
  ['2023-07-14', 16095],
  ['2023-07-15', 16740],
  ['2023-07-16', 12980],
  ['2023-07-17', 17545],
  ['2023-07-18', 16834],
  ['2023-07-19', 16474],
  ['2023-07-20', 16790],
  ['2023-07-21', 16331],
  ['2023-07-22', 16469],
  ['2023-07-23', 13680],
  ['2023-07-24', 15490],
  ['2023-07-25', 15968],
  ['2023-07-26', 15196],
  ['2023-07-27', 14147],
  ['2023-07-28', 14164],
  ['2023-07-29', 14295],
  ['2023-07-30', 14066],
  ['2023-07-31', 16081],
  ['2023-08-01', 19871],
  ['2023-08-02', 17114],
  ['2023-08-03', 17295],
  ['2023-08-04', 16653],
  ['2023-08-05', 16914],
  ['2023-08-06', 12838],
  ['2023-08-07', 14746],
  ['2023-08-08', 17421],
  ['2023-08-09', 17562],
  ['2023-08-10', 17351],
  ['2023-08-11', 16573],
  ['2023-08-12', 16344],
  ['2023-08-13', 14221],
  ['2023-08-14', 16048],
  ['2023-08-15', 17120],
  ['2023-08-16', 16412],
  ['2023-08-17', 17347],
  ['2023-08-18', 16309],
  ['2023-08-19', 16101],
  ['2023-08-20', 14374],
  ['2023-08-21', 16542],
  ['2023-08-22', 17292],
  ['2023-08-23', 25582],
  ['2023-08-24', 22600],
  ['2023-08-25', 17106],
  ['2023-08-26', 16354],
  ['2023-08-27', 14961],
  ['2023-08-28', 16910],
  ['2023-08-29', 19394],
  ['2023-08-30', 17641],
  ['2023-08-31', 27530],
  ['2023-09-01', 20097],
  ['2023-09-02', 17710],
  ['2023-09-03', 14845],
  ['2023-09-04', 17199],
  ['2023-09-05', 16971],
  ['2023-09-06', 19684],
  ['2023-09-07', 27579],
  ['2023-09-08', 23245],
  ['2023-09-09', 21208],
  ['2023-09-10', 16854],
  ['2023-09-11', 17414],
  ['2023-09-12', 17478],
  ['2023-09-13', 15983],
  ['2023-09-14', 16454],
  ['2023-09-15', 16363],
  ['2023-09-16', 16839],
  ['2023-09-17', 13830],
  ['2023-09-18', 16109],
  ['2023-09-19', 16528],
  ['2023-09-20', 15676],
  ['2023-09-21', 15979],
  ['2023-09-22', 15509],
  ['2023-09-23', 18315],
  ['2023-09-24', 14890],
  ['2023-09-25', 16159],
  ['2023-09-26', 16258],
  ['2023-09-27', 23651],
  ['2023-09-28', 16682],
  ['2023-09-29', 16396],
  ['2023-09-30', 29325],
  ['2023-10-01', 17374],
  ['2023-10-02', 16824],
  ['2023-10-03', 25000],
  ['2023-10-04', 21934],
  ['2023-10-05', 18408],
  ['2023-10-06', 16632],
  ['2023-10-07', 17627],
  ['2023-10-08', 15503],
  ['2023-10-09', 14858],
  ['2023-10-10', 16924],
  ['2023-10-11', 15979],
  ['2023-10-12', 15736],
  ['2023-10-13', 18276],
  ['2023-10-14', 18041],
  ['2023-10-15', 15821],
  ['2023-10-16', 14871],
  ['2023-10-17', 15754],
  ['2023-10-18', 20739],
  ['2023-10-19', 16160],
  ['2023-10-20', 16205],
  ['2023-10-21', 19787],
  ['2023-10-22', 17202],
  ['2023-10-23', 14690],
  ['2023-10-24', 19409],
  ['2023-10-25', 3082],
];
