import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
// import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

import { UserContent, UserButton } from './user-menu.styled';

export type UserMenuProps = {
  userName: string;
  menuIcon: JSX.Element;
  items: Array<IMenuItem>;
};

export const UserMenu = (props: UserMenuProps) => {
  const { userName, menuIcon, items } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // click on item
  const click = (el: IMenuItem) => {
    handleClose();
    el.action && el.action();
  };

  // gen menu items
  const itemsEl = items.map((el, idx) => {
    if (el.divider) {
      return <Divider key={'menu' + idx} />;
    }
    if (el.component) {
      return el.component;
    }
    return (
      <MenuItem onClick={() => click(el)} key={'menu' + idx} disabled={el.disabled}>
        {el.icon && <ListItemIcon>{el.icon}</ListItemIcon>}
        <ListItemText>{el.title}</ListItemText>
      </MenuItem>
    );
  });

  return (
    <UserContent>
      {/* <Name>{userName}</Name>
      <div> */}
      <UserButton
        variant="text"
        size="medium"
        color="inherit"
        endIcon={menuIcon}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
      >
        {userName}
      </UserButton>
      {/*
        <IconButton
          size="medium"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          {menuIcon}
  </IconButton>*/}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        // keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {itemsEl}
      </Menu>
      {/* </div> */}
    </UserContent>
  );
};

export default UserMenu;
