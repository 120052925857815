import { useState } from 'react';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';

import { TabPanel } from './tab-panel';

export type ITab = {
  title: string;
  component: any;
};

export type ITabs = {
  title?: string;
  items: Array<ITab>;
  current: number;
};

function a11yProps(idx: number) {
  return {
    id: 'tab-' + idx,
    'aria-controls': 'tabpanel-' + idx,
  };
}

export function Tabs(props: ITabs) {
  const { items, title, current } = props;
  const [value, setValue] = useState(current);

  const handleChange = (__event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <MuiTabs value={value} onChange={handleChange} aria-label={title}>
        {items.map((el, idx) => {
          return <MuiTab key={idx} label={el.title} {...a11yProps(idx)} />;
        })}
      </MuiTabs>

      {items.map((el, idx) => {
        return (
          <TabPanel key={idx} active={value === idx} idx={idx}>
            {el.component}
          </TabPanel>
        );
      })}
    </>
  );
}

Tabs.defaultProps = {
  current: 0,
};

export default Tabs;
