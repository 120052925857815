import { useLocation, Navigate } from 'react-router-dom';

import { useStore } from 'app/store';
import { links } from 'libs/data-config';
import { LayoutBase, Container, Footer } from 'libs/layout';
import { Content } from './layout-public.styled';

interface IProps {
  children: JSX.Element | JSX.Element[];
  toLoginedPath: string;
}

export function LayoutPublic(props: IProps): JSX.Element {
  const { toLoginedPath } = props;
  const location = useLocation();
  const isLoginPath = location.pathname === links.login;
  const userLogined = useStore((state) => state.userLogined);
  // console.log('🚀 ~ LayoutPublic ~ userLogined:', userLogined);

  if (userLogined && isLoginPath) {
    // console.log('🚀 navigate to logined', toLoginedPath);
    return <Navigate to={toLoginedPath} />;
  }

  return (
    <LayoutBase>
      <Container vcenter>
        <Content>{props.children}</Content>
      </Container>
      <Footer />
    </LayoutBase>
  );
}

export default LayoutPublic;
