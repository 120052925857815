// import CircularProgress from '@mui/material/CircularProgress';
import { LoaderIcon } from 'libs/icons';
import { StyledLoader, LoaderPlace } from './loader.styled';
export type ILoader = {
  fixed?: boolean;
};

export function Loader(props: ILoader) {
  return (
    <StyledLoader {...props}>
      <LoaderPlace>
        {/* <CircularProgress /> */}
        <LoaderIcon />
      </LoaderPlace>
    </StyledLoader>
  );
}

Loader.defaultProps = {
  fixed: true,
};

export default Loader;
