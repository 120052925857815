import { Controller } from 'react-hook-form';

import { SelectInput, SelectInputProps } from 'libs/ui';
import { Field } from '../field/field';

export const SelectField = (props: SelectInputProps & FormFieldProps) => {
  const { control, fullWidth, ...rest } = props;

  // render
  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message;
        const fieldProps: any = {
          error: fieldState.invalid,
          fullWidth: fullWidth,
          // helperText: fieldState.error?.message,
        };
        if (errorMessage) {
          fieldProps.helperText = errorMessage;
        }

        const params = { ...field, ...rest, ...fieldProps };
        return <Field Cmp={SelectInput} params={params} />;
      }}
    />
  );
};

SelectField.defaultProps = {
  control: null,
};

export default SelectField;
