import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StyledLoader = styled.div<{ fixed?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  svg {
    width: 20px;
    height: 20px;
  }
  ${(props) =>
    props.fixed &&
    css`
      position: fixed;
    `}
`;

export const LoaderPlace = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex: 1 0 0;
`;
