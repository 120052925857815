import { Navigate } from 'react-router-dom';

import { useStore } from 'app/store';
import { LayoutBase, Footer, Header, Container } from 'libs/layout';
import { Content } from './layout-private.styled';

interface ILayoutPrivate {
  children: JSX.Element;
  toLoginPath: string;
  isAdmin?: boolean;
}

export function LayoutPrivate(props: ILayoutPrivate): JSX.Element {
  const userLogined = useStore((state) => state.userLogined);
  // console.log('🚀 ~ LayoutPrivate ~ userLogined:', userLogined);

  /**
   * is logined
   */
  if (!userLogined) {
    return <Navigate to={props.toLoginPath} />;
  }

  return (
    <LayoutBase>
      <Header />
      <Container>
        <Content>{props.children}</Content>
      </Container>
      <Footer />
    </LayoutBase>
  );
}

export default LayoutPrivate;
