import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useFormContext } from 'react-hook-form';

import { Button, ButtonProps } from 'libs/ui';

export type ResetButtonProps = { onSubmit: any } & ButtonProps;

export const ResetButton = (props: ResetButtonProps) => {
  const { onClick, onSubmit, ...rest } = props;
  const { reset, handleSubmit } = useFormContext();

  const click = () => {
    reset();
    handleSubmit(onSubmit)();
  };

  return (
    <Button {...rest} onClick={click} startIcon={<RestartAltIcon />}>
      Отмена
    </Button>
  );
};

ResetButton.defaultProps = {
  color: 'inherit',
  size: 'medium',
  className: 'filter-btn reset',
};

export default ResetButton;
