import styled from '@emotion/styled';

export const Main = styled.div`
  height: 100%;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  @media (max-width: 639px) {
    padding: 0;
  }
`;
