import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const StyledLogo = styled(Link)`
  width: 66px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.75rem;
  transition: 0.2s all ease-in;
  color: #fff;
  text-decoration: none;
  margin: 0 2rem 0 0;
  & img {
    max-width: 100%;
    height: auto;
  }
  span {
    margin-left: 10px;
    display: block;
  }

  &:hover {
    text-decoration: none;
    color: #d9d9d9;
  }

  @media (max-width: 639px) {
    margin: 0 1rem 0 0;    
    width: 56px;
  }  
`;
