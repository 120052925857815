import MuiTabs from '@mui/material/Tabs';

import { LinkTab } from './link-tab';
import { useRouteMatch } from 'libs/hooks';

export type ILinkTab = {
  title: JSX.Element;
  to: string;
  value?: string;
};

export type ILinkTabs = {
  title?: string;
  items: Array<ILinkTab>;
};

function a11yProps(idx: number) {
  return {
    id: 'tab-' + idx,
    'aria-controls': 'tabpanel-' + idx,
  };
}

export function LinkTabs(props: ILinkTabs) {
  const { items, title } = props;

  const getValue = (el: ILinkTab) => (el.value ? el.value : el.to);

  const test = items.map((el) => getValue(el));
  const routeMatch = useRouteMatch(test);
  const currentTab = routeMatch?.pattern?.path;

  return (
    <MuiTabs value={currentTab} aria-label={title}>
      {items.map((el, idx) => {
        const value = getValue(el);
        return <LinkTab to={el.to} key={idx} label={el.title} value={value} {...a11yProps(idx)} />;
      })}
    </MuiTabs>
  );
}

export default LinkTabs;
