import SmsIcon from '@mui/icons-material/Sms';

import { useStore } from 'app/store';
import { StyledLogo } from './logo.styled';

export const Logo = () => {
  const userLogined = useStore((state) => state.userLogined);

  const to = userLogined ? '/' : '/login';

  return <StyledLogo to={to}><SmsIcon /> <span>SMS</span></StyledLogo>;
};

export default Logo;
