//import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Content = styled.div`
  .filter-action-btn {
    font-size: 0.775rem;
  }
`;

export const ButtonContent = styled.div`  
`;

export const Info = styled.div`
  font-size: 10px;
  line-height: normal;
  text-align: center;
  color: #aaa;
  margin: 7px 0 0;
`;
