import { useState } from 'react';
import MUITable, { TableProps as MUITableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import TableHeader from './table-header';
import TableRow from './table-row';
import { TableFooter } from './table.styled';
import { Loader, Confirm } from 'libs/ui';

export type TableProps = {
  minWidth?: number;
  title?: string;
  rows: ICountRows | undefined;
  fields: Array<ITableField>;
  actions?: Array<ITableAction>;
  orderBy: string;
  order: IOrderDirection;
  onRowClickLinks?: boolean;
  dense?: boolean;
  control?: object;
  footer?: any;
  rowTitle?: string;
  onRowClick?: (row: any) => void;
  getRowColor?: (row: any) => string;
  getRowPopup?: (row: any) => any;
  getRowLink?: (row: any) => any;
  deleteAction?: IDeleteAction;
  changeSort?: (order: IOrderDirection, orderBy: string) => void;
  withoutLoader?: boolean;
};

/**
 * table component
 */
export function Table(props: TableProps) {
  const {
    footer,
    minWidth,
    title,
    order,
    orderBy,
    fields,
    rows,
    actions,
    dense,
    rowTitle,
    onRowClick,
    onRowClickLinks,
    getRowColor,
    getRowPopup,
    getRowLink,
    changeSort,
    withoutLoader,
  } = props;
  const [showConfirm, setShowConfirm] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  /**
   * request sort
   * @param __event
   * @param field
   */
  const handleRequestSort = (__event: React.MouseEvent<unknown>, field: string) => {
    const isAsc = orderBy === field && order === 'asc';
    const direction = isAsc ? 'desc' : 'asc';
    if (changeSort) {
      changeSort(direction, field);
    }
  };

  if (!rows) {
    return withoutLoader ? null : <Loader fixed={false} />;
  }

  if (rows.count === 0) {
    return <p>Записи отсутствуют</p>;
  }

  /**
   * delete action with confirm
   * @param row
   * @returns
   */
  const deleteAction = (row: any) => {
    const action = props.deleteAction;
    if (!action) {
      return;
    }

    let title = 'Удалить запись';
    if (rowTitle) {
      title += ' "' + row[rowTitle] + '"';
    }

    setShowConfirm({
      active: true,
      confirmText: title,
      confirmAction: () => {
        action(
          row,
          () => {
            closeConfirm();
          },
          (error: any) => {
            const out = typeof error === 'object' ? JSON.stringify(error) : error;
            //console.log('error', error);
            setError(out);
          }
        );
      },
    });
  };

  /**
   * find field by name
   * @param fn
   * @returns
   */
  const getField = (fn: string) => {
    const out = fields.find((el) => el.name === fn);
    return out ? out : fields[0];
  };

  const closeConfirm = () => {
    setShowConfirm(null);
  };

  const withDelete = !!props.deleteAction;
  const rowParams: any = { onRowClick, onRowClickLinks, getRowLink, actions, fields, getRowColor, getRowPopup };
  if (withDelete) {
    rowParams.deleteAction = deleteAction;
  }

  const tableProps: MUITableProps = {};
  if (dense) {
    tableProps.size = 'small';
  }

  /**
   * get rows el
   * @returns
   */
  const getRows = () => {
    return rows.items.map((row, idx) => {
      return <TableRow key={idx} row={row} {...rowParams} />;
    });
  };

  const rowsEl = getRows();

  return (
    <>
      <TableContainer>
        <MUITable sx={{ minWidth }} aria-labelledby={title} {...tableProps}>
          <TableHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} fields={fields} actions={actions} withDelete={withDelete} />
          <TableBody>{rowsEl}</TableBody>
        </MUITable>
        {footer && <TableFooter>{footer}</TableFooter>}
      </TableContainer>
      {showConfirm && showConfirm.active && (
        <Confirm
          error={error}
          open={true}
          close={closeConfirm}
          title="Подтвердите действие"
          info={showConfirm.confirmText}
          action={showConfirm.confirmAction}
          actionTitle="Подтвердить"
        />
      )}
    </>
  );
}
Table.defaultProps = {
  minWidth: 750,
  title: '',
  defaultOrder: 'asc',
  defaultOrderBy: '',
  rowsPerPage: 10,
  rowsPerPageOptions: [5, 10, 25],
  rowTitle: 'title',
};

export default Table;
