import { createTheme } from '@mui/material/styles';
import { ruRU } from '@mui/x-date-pickers/locales';

export const defaultTheme = createTheme(
  {
    //palette: colors,
    //typography: {
    //fontFamily: "'IBM Plex Sans', sans-serif",
    //},
  },
  ruRU
);

defaultTheme.components = {
  // global
  MuiCssBaseline: {
    styleOverrides: {
      'body, html': {
        //height: '100vh',
        //overflow: 'hidden',
      },
      '#root': {
        height: '100vh',
      },
    },
  },
  // table
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&.MuiTableRow-hover:hover': {
          cursor: 'pointer',
        },
      },
    },
  },

  // dialog
  MuiDialog: {
    styleOverrides: {
      paper: {
        margin: 0,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        minWidth: '290px',
        padding: '20px',
        lineHeight: '1.1',
        '& .close': {
          position: 'absolute',
          right: '10px',
          top: '10px',
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '20px',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        justifyContent: 'center',
        button: {
          minWidth: '140px',
          margin: '0 15px',
        },
      },
    },
  },

  MuiTableCell: {
    styleOverrides: {
      root: {
        '&.action-cell': {
          paddingTop: '0',
          paddingBottom: '0',
        },
      },
    },
  },

  // button
  MuiButton: {
    styleOverrides: {
      root: {
        '&.MuiButton-sizeMedium': {
          paddingTop: '8px',
          paddingBottom: '7px',
        },
      },
    },
  },
};

export default defaultTheme;
