import styled from '@emotion/styled';
// import { css } from '@emotion/react';

export const Content = styled.div`
  margin: 0 0 2.5rem;
  &:last-child {
    margin: 0;
  }
`;

export const Title = styled.div`
  margin: 0 0 1rem;
  font-size: 1.75rem;
  line-height: 1.214;
  letter-spacing: 0.02em;
  color: #101820;
`;

export const Items = styled.div`
  max-width: 33rem;
`;
