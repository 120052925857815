import { useState, useEffect, useMemo } from 'react';

import { Table, TableProps } from './table';
import { PagerWithNumbers } from './pager/table-pager';
import { TableFilter } from '../table-filter/table-filter';
import { TableFilterActions } from '../table-filter/table-filter-actions';
import { Content, FilterContent } from './pager-table.styled';

/**
 * get initial filters
 * @param filters
 * @returns
 */
const getInitialFilter = (filters?: Array<ITableFilter>) => {
  const initial: Record<string, any> = {};
  if (!filters) {
    return initial;
  }
  filters.forEach((el: ITableFilter) => {
    initial[el.name] = el.initial; // || '';
  });
  return initial;
};

export type PagerTableProps = Omit<TableProps, 'order' | 'orderBy'> & {
  // pager
  // count: number;
  storage: StorageInterface;
  filters?: Array<ITableFilter>;
  filterActions?: Array<IFilterAction>;
  rowsPerPage: number;
  rowsPerPageOptions: Array<number>;
  updateRows: IUpdateRowsFunc;
  updatePeriod?: number;
  defaultOrder: IOrderDirection;
  defaultOrderBy: string;
  loading?: boolean;
};

/**
 * pager table component
 */
export function PagerTable(props: PagerTableProps) {
  const { rowsPerPage, updateRows, updatePeriod, filters, filterActions, defaultOrder, defaultOrderBy, ...rest } = props;
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(rowsPerPage);
  const initialFilter = getInitialFilter(props.filters);
  const [filter, setFilter] = useState<Record<string, any>>(initialFilter);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  /**
   * get initial data + periodical refresh
   */
  useEffect(() => {
    let timer1: any;
    if (updatePeriod) {
      timer1 = setInterval(() => setRefresh((val) => !val), updatePeriod);
      setRefresh((val) => !val);
    }

    return () => {
      if (timer1) {
        clearInterval(timer1);
      }
    };
  }, [updatePeriod]);

  /**
   * change page
   * @param __event
   * @param page
   */
  const handleChangePage = (__event: IEvent | null, page: number) => {
    setPage(page);
  };

  /**
   * change rows per page
   * @param event
   */
  const handleChangeRowsPerPage = (event: IInputEvent) => {
    const val = parseInt(event.target.value);
    setPerPage(val);
    setPage(0);
  };

  /**
   * update filter
   * @param data
   */
  const updateFilter = (data: any) => {
    setFilter(data);
    setPage(0);
    return true;
  };

  const filterString = JSON.stringify(filter);

  /**
   * convert order to string
   * @param order
   * @param orderBy
   * @returns
   */
  const convertOrder = (order: IOrderDirection, orderBy: string) => {
    return (order === 'desc' ? '-' : '') + orderBy;
  };

  /**
   * refresh rows
   */
  useEffect(() => {
    // console.log('🚀 ~ refresh rows', filterString, page, perPage, refresh, order, orderBy);

    const refreshRows = () => {
      setLoading(true);
      updateRows(page * perPage, perPage, convertOrder(order, orderBy), filter);
    };

    refreshRows();
    return () => {
      // console.log('clean effect');
      setLoading(false);
    };
  }, [filterString, page, perPage, refresh, order, orderBy]);

  /**
   * disable loader
   */
  useEffect(() => {
    setLoading(false);
  }, [props.rows]);

  /**
   * change sort order
   * @param order
   * @param orderBy
   */
  const changeSort = (order: IOrderDirection, orderBy: string) => {
    setOrder(order);
    setOrderBy(orderBy);
  };

  const orderString = useMemo(() => convertOrder(order, orderBy), [order, orderBy]);
  // console.log('🚀 ~ PagerTable ~ orderString:', orderString);

  /**
   * pagination
   */
  if (props.rows) {
    rest.footer = (
      <PagerWithNumbers
        labelRowsPerPage={'На странице'}
        count={props.rows.count}
        rowsPerPageOptions={props.rowsPerPageOptions}
        rowsPerPage={perPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    );
  }

  const cl = loading || props.loading ? 'loading' : '';

  return (
    <Content className={cl}>
      {(!!filters?.length || !!filterActions?.length) && (
        <FilterContent>
          {filters ? <TableFilter initialFilter={initialFilter} filters={filters} onChange={updateFilter} /> : <div></div>}
          {filterActions && <TableFilterActions actions={filterActions} filter={filter} orderString={orderString} />}
        </FilterContent>
      )}
      <Table {...rest} changeSort={changeSort} order={order} orderBy={orderBy} withoutLoader={true} />
    </Content>
  );
}

PagerTable.defaultProps = {
  rowsPerPage: 10,
  rowsPerPageOptions: [5, 10, 25],
  updatePeriod: 0,
};

export default PagerTable;
