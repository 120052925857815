import { List, BottomButtons, Button } from 'libs/ui';
import { Content, Body } from './message.styled';

export type MessageProps = {
  row: any;
  fields: ITableFields;
  close: () => void;
};

export const Message = (props: MessageProps) => {
  const { row, close, fields } = props;

  // fields map
  const fieldsMap: Record<string, ITableField> = {};
  fields.forEach((el) => {
    fieldsMap[el.name] = el;
  });

  // items
  const items = Object.keys(row)
    .filter((fn) => !['id', 'body'].includes(fn))
    .map((fn) => {
      let value = row[fn];
      const field = fieldsMap[fn];
      if (!field) {
        return { title: fn, value };
      }

      const title = field?.title;
      value = field.render ? field.render(value, null, row) : value;

      return { title, value };
    });

  return (
    <Content>
      <List items={items} />

      <Body>{row.body}</Body>

      <BottomButtons nopad>
        <div></div>
        <Button onClick={close}>Закрыть</Button>
      </BottomButtons>
    </Content>
  );
};

export default Message;
