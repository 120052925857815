export const configMock = {
  operators: [
    {
      name: 'МТС',
      value: 'mts',
    },
    {
      name: 'Теле2',
      value: 'tele2',
    },
    {
      name: 'Билайн',
      value: 'beeline',
    },
    {
      name: 'Мегафон',
      value: 'megafon',
    },
    {
      name: 'Yota',
      value: 'yota',
    },
    {
      name: 'Тинькофф',
      value: 'tinkoff',
    },
  ],
  senders: [
    {
      name: '3065',
      value: 3065,
    },
  ],
};
