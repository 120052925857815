/**
 * User service
 *
 * @summary implements API calls
 */
import { api } from 'api';
import { notify } from 'libs/common';
import { setUser, logout } from 'app/store';
import { UserService } from './user-service.class';

/**
 * set user info
 * @param data
 */
const actionSetUser = (data: any) => {
  setUser(data);
};

/**
 * logout
 */
const actionLogout = () => {
  logout();
  api.logout();
};

const actions = {
  notify,
  setUser: actionSetUser,
  logout: actionLogout,
};

export const userService = new UserService({ api, actions });
export default userService;
