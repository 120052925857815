// import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';

import { DateTimeInput, DateTimeInputProps } from 'libs/ui';
import { Field } from '../field/field';

export const DateTimeField = (props: DateTimeInputProps & FormFieldProps) => {
  const { control, ...rest } = props;

  // render
  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field, fieldState }) => {
        // const change = (value: any) => {
        // form.setFieldValue(field.name, value);
        // };

        const fieldProps = {
          slotProps: {
            textField: {
              error: fieldState.invalid,
              helperText: fieldState.error?.message,
              // size: 'small',
            },
          },
          format: 'D MMM YYYY HH:mm'
          // onError: (data: any) => {
          //   console.log('🚀 ~ DateTimeInput ~ data:', data);
          // },
          // error: fieldState.invalid,
          // helperText: fieldState.error?.message,
          // onChange: change,
        };
        const params = { ...field, ...rest, ...fieldProps };
        return <Field Cmp={DateTimeInput} params={params} />;
      }}
    />
  );
};

DateTimeField.defaultProps = {
  control: null,
};

export default DateTimeField;
