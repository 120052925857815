import styled from '@emotion/styled';
// import { css } from '@emotion/react';

export const Content = styled.div<{$last?: boolean}>`
  display: flex;
  height: 3.5rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eff2f4;
  letter-spacing: 0.02em;
  line-height: 1.1875;
  &:last-child {
    border: 0;
  }
`;
export const Left = styled.div`
  display: block;
  color: #9299a2;
`;
export const Right = styled.div`
  display: block;
  color: #000;
  text-align: right;

  i {
    font-style: normal;
    color: gray;
  }
`;
