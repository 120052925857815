import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { mocks } from './mock';

const mock = false;

export class BstrApi {
  loginMethod = 'api/auth/login';
  publicMethods = ['api/auth/login'];
  lastMethod?: string;
  token?: UserLoginResponse;

  storage;
  protocol;
  transport;
  notifyFunc;
  unsetAuth;
  debug;
  getErrorMessage;

  constructor(params: BstrApiParams) {
    const { unsetAuth, ProtocolClass, StorageClass, TransportClass, apiUrl, timeout, debug, notify, getErrorMessage } = params;

    // init
    this.storage = new StorageClass('api');
    this.protocol = new ProtocolClass({ getErrorMessage });
    this.transport = new TransportClass({
      apiUrl,
      timeout,
      prepareRequest: this.prepareRequest,
      prepareResponse: this.prepareResponse,
      prepareError: this.prepareError,
    });
    this.notifyFunc = notify;
    this.unsetAuth = unsetAuth;
    this.debug = debug;
    this.getErrorMessage = getErrorMessage;

    // token
    this.token = this.storage.getItem('token');
  }

  /**
   * is public method
   * @param method
   * @returns
   */
  isPublicMethod = (): boolean => (this.lastMethod ? this.publicMethods.includes(this.lastMethod) : true);

  /**
   * is login method
   * @returns
   */
  isLoginMethod = (): boolean => this.lastMethod === this.loginMethod;

  /**
   * logout user
   */
  logout() {
    this.storage.setItem('token', null);
    this.token = undefined;
    this.unsetAuth();
  }

  /**
   * get token
   * @returns
   */
  getToken(): UserLoginResponse | undefined {
    return this.token;
  }

  /**
   * set auth token if needed
   * @param {*} req
   */
  setRequestAuth = (req: AxiosRequestConfig) => {
    if (!this.isPublicMethod()) {
      const token = this.getToken();
      if (token) {
        if (req.headers) {
          req.headers['authorization'] = 'Bearer ' + token.access_token;
        }
      }
    }
    return req;
  };

  /**
   * prepare request
   * @param req
   * @returns
   */
  prepareRequest = (req: AxiosRequestConfig) => {
    this.lastMethod = req.url;

    // packet
    req.data = this.protocol.createPacket(req.url ? req.url : '', req.data);
    //req.url = '';

    // auth
    return this.setRequestAuth(req);
  };

  /**
   * prepare response
   * @param res
   * @returns
   */
  prepareResponse = (res: AxiosResponse) => {
    const isLoginMethod = this.isLoginMethod();
    const out = this.protocol.decodePacket(res, isLoginMethod);

    if (isLoginMethod) {
      this.storage.setItem('token', out.packet);
      this.token = out.packet;
    }
    return out.packet;
  };

  /**
   * prepare error
   * @param error
   * @returns
   */
  prepareError = (error: AxiosError) => {
    const authError = error.response?.status === 401 || error.response?.status === 403;
    const isLoginMethod = this.isLoginMethod();
    // console.log('🚀 ~ authError:', authError);
    if (authError && !isLoginMethod) {
      this.logout();
      return;
    }
    const msg = this.protocol.getError(error);
    if (!error.response) {
      this.notify('Ошибка соединения', msg);
    }
    return Promise.reject(msg);
  };

  /**
   * user notify
   * @param title
   * @param message
   */
  notify = (title: string, message: string) => {
    if (this.notifyFunc) {
      this.notifyFunc(title, message);
    }
  };

  /**
   * mock request
   */
  doMock = (method: string, params?: any) => {
    return mocks[method];
  };

  /**
   * test for mock
   * @param method
   * @returns
   */
  testMock = (method: string) => {
    return mock && !!mocks[method];
  };

  /**
   * get call
   * @param method
   * @param params
   * @returns
   */
  async get(method: string, params?: any) {
    if (this.testMock(method)) {
      return this.doMock(method, params);
    }
    return await this.transport.get(method, params);
  }

  /**
   * post call
   * @param method
   * @param params
   * @returns
   */
  async post(method: string, params?: any) {
    if (this.testMock(method)) {
      return this.doMock(method, params);
    }
    return await this.transport.post(method, params);
  }

  /**
   * put call
   * @param method
   * @param params
   * @returns
   */
  async put(method: string, params?: any) {
    if (this.testMock(method)) {
      return this.doMock(method, params);
    }
    return await this.transport.put(method, params);
  }

  /**
   * delete call
   * @param method
   * @param params
   * @returns
   */
  async delete(method: string, params?: any) {
    if (this.testMock(method)) {
      return this.doMock(method, params);
    }
    return await this.transport.delete(method, params);
  }
}
