import { useState, useEffect } from 'react';

import { BottomButtons, Button, Modal } from 'libs/ui';
import { downloadFile, notify } from 'libs/common';
import { checkReport } from 'libs/services';
import { FileName } from './downloader.styled';

export type DownloaderProps = {
  reportId: any;
  cancel: () => void;
};

type CheckReportResult = {
  link: string;
  status: string;
};

export const Downloader = (props: DownloaderProps) => {
  const { reportId, cancel } = props;
  const [ready, setReady] = useState(false);
  const [link, setLink] = useState('');

  // check status
  useEffect(() => {
    const timer = setInterval(() => {
      checkReport(reportId)
        .catch((__error) => {
          cancel();
        })
        .then((data: CheckReportResult) => {
          // console.log('🚀 ~ timer ~ data:', data);
          setLink(data.link);
          if (data.status === 'SUCCESS') {
            setReady(true);
            clearInterval(timer);
          }
        });
    }, 1000);

    return () => {
      timer && clearInterval(timer);
    };
  }, []);

  if (!reportId) {
    return null;
  }

  /**
   * donwload
   */
  const download = () => {
    downloadFile(link, true);
    notify('Скачать CSV', 'Отчёт сохранён', { type: 'success' });
    cancel();
  };

  const getFileName = (fn: string) => {
    if (!fn) {
      return fn;
    }
    const tmp = fn.split('/');
    return tmp[tmp.length - 1];
  };

  const fn = getFileName(link);
  const cl = !ready ? 'loading' : '';

  return (
    <Modal close={cancel} open={true} title="Формирование отчёта">
      <FileName className={cl}>{fn}</FileName>
      <BottomButtons nopad>
        <Button color="inherit" onClick={cancel}>
          Отмена
        </Button>
        <Button color="warning" onClick={download} disabled={!ready}>
          Скачать CSV
        </Button>
      </BottomButtons>
    </Modal>
  );
};
