import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Item = styled.div`
  flex-shrink: 0;
  margin: 0 1rem 1rem 0;
  /* max-width: 210px; */
  width: 218px;
  .MuiInputBase-root {
    font-size: 0.875rem;
  }
  legend {
    font-size: 0.87em;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div<{$vertical?: boolean}>`
  form {
    display: flex;
    flex-wrap: wrap;
  }
  .filter-btn {
    font-size: 0.775rem;
  }

  ${props => props.$vertical && css`
    form {
      display: block;
    }
    .form-control{
      margin: 0 0 15px;
    }
  `}
`;
