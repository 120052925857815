import React from 'react';
import { Place } from './field.styled';

export type FieldProps = {
  Cmp: any;
  params: any;
};

export const Field = React.forwardRef((props: FieldProps, ref) => {
  const { Cmp, params } = props;
  const Container = params.container ? params.container : Place;
  // console.log('🚀 ~ Field ~ params:', params.helperText);

  return (
    <Container className="form-control">
      <Cmp {...params} ref={ref} />
    </Container>
  );
});

export default Field;
