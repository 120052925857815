import React from 'react';
import { useForm, UseFormProps, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Place, FormError } from './form.styled';

export const Form = ({ defaultValues, children, onSubmit, validationSchema, absoluteErrors, hideLoading }: FormProps) => {
  const formParams: UseFormProps = { defaultValues };
  if (validationSchema) {
    formParams.resolver = yupResolver(validationSchema);
  }
  const methods = useForm(formParams);
  const { handleSubmit, control, formState, setError, reset } = methods;

  const { errors, isSubmitting } = formState;

  const helpers = {
    setError,
    // formState,
  };

  /**
   * submit form
   * @param data
   */
  const submit = (data: any) => {
    return onSubmit(data, helpers);
  };

  // errors
  const serverError = errors?.serverError?.message;
  // console.log('🚀 ~ Form ~ errors:', errors);

  // loading
  const cl = !hideLoading && isSubmitting ? 'loading' : '';

  return (
    <Place className={cl}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submit)}>
          {React.Children.map(children, (child) => {
            return child?.props.name
              ? React.createElement(child.type, {
                  ...{
                    ...child.props,
                    control,
                    key: child.props.name,
                  },
                })
              : child;
          })}
          {serverError && (
            <FormError absoluteErrors={absoluteErrors}>
              <p>{serverError.toString()}</p>
            </FormError>
          )}
        </form>
      </FormProvider>
    </Place>
  );
};

Form.defaultProps = {
  hideLoading: false,
};

export default Form;
