import { StyledContent, InnerStyled } from './controls.styled';

export const Content = (props: any) => {
  const { children, ...rest } = props;
  return <StyledContent {...rest}>{children}</StyledContent>;
};

Content.defaultProps = {
  short: false,
  centered: false,
};

export const ContentInner = (props: any) => {
  const { children, ...rest } = props;
  return <InnerStyled {...rest}>{children}</InnerStyled>;
};
