import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';

import { useStore } from 'app/store';
import { userService } from 'libs/services';
import { UserMenu, UserMenuProps } from 'libs/ui';
// import { MenuIcon } from 'libs/icons';
import { getUserName } from 'libs/common';
import { UserContent } from './user-widget.styled';

export const UserWidget = () => {
  const userLogined = useStore((state) => state.userLogined);
  const user = useStore((state) => state.user);
  if (!userLogined) {
    return null;
  }

  const navigate = useNavigate();

  /**
   * user logout
   */
  const logout = () => {
    userService.logout();
  };

  /**
   * to profile
   */
  const gotoProfile = () => {
    navigate('/profile');
  };

  const items: UserMenuProps['items'] = [{ title: 'Профиль', action: gotoProfile }, { divider: true }, { title: 'Выход', action: logout }];
  const name = getUserName(user);

  return (
    <UserContent>
      <UserMenu userName={name} menuIcon={<PersonIcon />} items={items} />
      {/* <Button onClick={logout} size="small">
        Выход
      </Button> */}
    </UserContent>
  );
}

export default UserWidget;
