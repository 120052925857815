// import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';

import { TextInput, TextInputProps } from 'libs/ui';

export const TextArea = (props: TextInputProps & { control: any; name: string }) => {
  const { control, ...rest } = props;

  // render
  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field, fieldState }) => {
        const fieldProps = {
          error: fieldState.invalid,
          helperText: fieldState.error?.message,
          multiline: true,
        };

        return <TextInput {...field} {...rest} {...fieldProps} />;
      }}
    />
  );
};

TextArea.defaultProps = {
  control: null,
  variant: 'outlined',
  size: 'small',
};

export default TextArea;
