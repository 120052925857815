import { lazy } from 'react';

// login
export const PageLogin = lazy(() =>
  import('app/pages/login').then(({ PageLogin }) => ({
    default: PageLogin,
  }))
);

// messages
// export const PageMain = lazy(() =>
//   import('app/pages/main').then(({ PageMain }) => ({
//     default: PageMain,
//   }))
// );

// users
export const PageUsers = lazy(() =>
  import('app/pages/users').then(({ PageUsers }) => ({
    default: PageUsers,
  }))
);

// profile
export const PageProfile = lazy(() =>
  import('app/pages/profile').then(({ PageProfile }) => ({
    default: PageProfile,
  }))
);

// users
export const PageMessages = lazy(() =>
  import('app/pages/messages').then(({ PageMessages }) => ({
    default: PageMessages,
  }))
);
