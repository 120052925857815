import styled from '@emotion/styled';
import Container, { ContainerProps } from '@mui/material/Container';

export type IContainerProps = ContainerProps;

export const StyledContainer = styled(Container)`
  height: 100%;
`;

export const StyledContainerCentered = styled(StyledContainer)`
  align-items: center;
  display: flex;
  justify-content: center;
  > * {
    flex-grow: 1;
  }
`;

export const StyledContent = styled.div`
  padding: 24px;
  @media (max-width: 639px) {
    padding: 15px;
  }
`;

export const Description = styled.div`
  font-size: 1.125rem;
  line-height: 1.125;
  text-align: center;
  color: #9299a2;
  margin: 0 auto 2.5rem;
  max-width: 35rem;
`;

export const Text = styled.div`
  font-size: 1.125rem;
  line-height: 1.222;
  color: #4f4f4f;
  text-align: left;
  margin: 0 auto;
  p {
    padding: 0;
    margin: 0 0 1.5em;
  }
`;
