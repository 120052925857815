import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { NavLink } from 'react-router-dom';

import { MenuIcon } from 'libs/icons';
import { Place, NavLinkCustom } from './menu.styled';

export type IMainMenuItem = {
  title: string;
  link: string;
};

export type MainMenuProps = {
  items: Array<IMainMenuItem>;
};

export const MainMenu = (props: MainMenuProps) => {
  const { items } = props;

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const getItems = (mobile: boolean) => {
    return items.map((item, idx) => {
      if (mobile) {
        return (
          <MenuItem key={idx} color="inherit" component={NavLinkCustom} to={item.link} onClick={handleCloseNavMenu}>
            {item.title}
          </MenuItem>
        );
      }
      return (
        <Button key={idx} color="inherit" component={NavLink} to={item.link} size="small" onClick={handleCloseNavMenu}>
          {item.title}
        </Button>
      );
    });
  };

  return (
    <>
      <Place sx={{ display: { xs: 'flex', sm: 'none' } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          sx={{ display: { xs: 'flex', sm: 'none' } }}
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
        >
          {getItems(true)}
        </Menu>
      </Place>
      <Place className="non-mobile" sx={{ display: { xs: 'none', sm: 'flex' } }}>
        {getItems(false)}
      </Place>
    </>
  );
};

export default MainMenu;
