import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

export function TableHeaderField(props: ITableHeaderField) {
  const { onRequestSort, name, title, numeric, align, orderBy, order, withSort, ...rest } = props;
  delete rest.render;
  delete rest.withPopover;

  const createSortHandler = (property: string) => (event: IEvent) => {
    onRequestSort(event, property);
  };

  return (
    <TableCell
      key={name}
      align={align ? align : numeric ? 'right' : 'left'}
      //padding={headCell.disablePadding ? 'none' : 'normal'}
      sortDirection={orderBy === name ? order : false}
      {...rest}
    >
      {withSort ? (
        <TableSortLabel active={orderBy === name} direction={orderBy === name ? order : 'asc'} onClick={createSortHandler(name)}>
          {title}
          {orderBy === name && (
            <Box component="span" sx={visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </Box>
          )}
        </TableSortLabel>
      ) : (
        <>{title}</>
      )}
    </TableCell>
  );
}

TableHeaderField.defaultProps = {
  numeric: false,
  withSort: false,
};

export default TableHeaderField;
