import { Controller } from 'react-hook-form';

import { TextInput, TextInputProps } from 'libs/ui';
import { Field } from '../field/field';

export const TextField = (props: TextInputProps & FormFieldProps) => {
  const { control, ...rest } = props;

  // render
  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue=""
      render={({ field, fieldState }) => {
        const fieldProps = {
          error: fieldState.invalid,
          helperText: fieldState.error?.message,
        };
        const params = { ...field, ...rest, ...fieldProps };
        return <Field Cmp={TextInput} params={params} />;
      }}
    />
  );
};

TextField.defaultProps = {
  control: null,
  variant: 'outlined',
  size: 'small',
};

export default TextField;
