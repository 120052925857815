import { hmac } from 'libs/common';

export type IUserServiceActions = {
  notify: any;
  setUser: (data: any) => void;
  logout: () => void;
};

export type IUserService = {
  api: any;
  actions: IUserServiceActions;
};

/**
 * service for interaction with user api
 *
 * @class UserService
 */
export class UserService {
  role: string = '';
  id: number = 0;
  api: any;
  actions: any;
  /**
   *
   * @param {*} param0 = {
   *  api - backend api
   *  actions - notify, logout, setUser
   * }
   */
  constructor({ api, actions }: IUserService) {
    this.api = api;
    this.actions = actions;
  }

  /**
   * 1. Check user token
   */
  async checkToken() {
    // if no token return
    if (!this.api.getToken()) {
      this.actions.logout();
      return Promise.resolve();
    }

    // get info
    return await this.getUser();
  }

  /**
   * get user
   */
  async getUser() {
    return await this.api.get('api/auth/me').then((data: UserMeResponse) => {
      //console.log('🚀 ~ result ~ data:', data);
      if (data) {
        this.id = data.id;
        this.role = data.role;
      }
      this.actions.setUser(data);
      return data;
    });
  }

  /**
   * Login request
   * @param params
   * @returns promise
   */
  async login(params: UserLoginParams) {
    let err = false;
    let errMessage = '';
    const res = await this.api.post('api/auth/login', params).catch((error: any) => {
      err = true;
      errMessage = error;
    });
    // console.log('🚀 ~ login ~ res:', res);

    // err
    if (err) {
      return this.getError(errMessage);
    }

    // get info
    return await this.getUser();
  }

  /**
   * logout request
   */
  async logout() {
    this.actions.logout();
  }

  /**
   * get users
   * @param params
   * @returns promise
   */
  async getUsers() {
    let params = {};
    let err = false;
    let errMessage = '';
    const res = await this.api.get('api/auth', params).catch((error: any) => {
      err = true;
      errMessage = error;
      // this.actions.notify('Получение списка сообщений', error.toString());
    });

    // err
    if (err) {
      return this.getError(errMessage);
    }

    // res
    return { items: res, count: res.length };
  }

  /**
   * add user
   * @param params
   * @returns promise
   */
  async addUser(data: IUserAdd) {
    let params = { ...data };
    params.password = hmac(params.password);
    let err = false;
    let errMessage = '';
    const res = await this.api.post('api/auth/register', params).catch((error: any) => {
      err = true;
      errMessage = error;
      // this.actions.notify('Добаление пользователя', error.toString());
    });

    // err
    if (err) {
      return this.getError(errMessage);
    }

    // res
    return res;
  }

  /**
   * update user
   * @param params
   * @returns promise
   */
  async updateUser(data: IUser) {
    const { id, ...params } = data;
    let err = false;
    let errMessage = '';
    const res = await this.api.put('api/auth/' + id, params).catch((error: any) => {
      err = true;
      errMessage = error;
      // this.actions.notify('Изменение пользователя', error.toString());
    });

    // err
    if (err) {
      return this.getError(errMessage);
    } else {
      if (id === this.id) {
        this.getUser();
      }
    }

    // res
    return res;
  }

  /**
   * change password
   * @param params
   * @returns promise
   */
  async changeUserPassword(data: IChangePassword) {
    const out = { ...data };
    out.new_password = hmac(out.new_password);
    out.old_password = hmac(out.old_password);
    let err = false;
    let errMessage = '';
    const res = await this.api.put('api/auth/update-password', out).catch((error: any) => {
      err = true;
      errMessage = error;
      //this.actions.notify('Изменение пароля пользователя', error.toString());
    });

    // err
    if (err) {
      return this.getError(errMessage);
    }

    // res
    return res;
  }

  getError(errMessage: any) {
    return { error: errMessage };
  }

  /**
   * del user
   * @param id
   * @returns
   */
  async delUser(id: number) {
    let err = false;
    let errMessage = '';
    const res = await this.api.delete('api/auth/' + id).catch((error: any) => {
      err = true;
      errMessage = error;
    });

    // err
    if (err) {
      return this.getError(errMessage);
    }

    // res
    return res;
  }
}
