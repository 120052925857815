// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import 'dayjs/locale/ru';
dayjs.extend(objectSupport);
dayjs.locale('ru');

import './index.css';
import App from 'app/app';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
    <ReactNotifications />
    <App />
  </LocalizationProvider>
);
