import { useState } from 'react';

import { Confirm } from 'libs/ui';

export type IUseConfirm = {
  title: string;
  info?: string;
  open?: boolean;
  closeTitle?: string;
  action?: any;
  actionTitle?: string;
  error?: string;
};

export const useConfirm = (data: IUseConfirm): [any, () => void] => {
  const [confirmState, setConfirmState] = useState<IUseConfirm>(data);

  /**
   * toggle
   */
  const showConfirm = () => {
    setConfirmState({ ...confirmState, open: true });
  };

  const closeConfirm = () => {
    setConfirmState({ ...confirmState, open: false });
  };

  const action = () => {
    data.action && data.action();
    closeConfirm();
  };

  const confirm = confirmState.open ? <Confirm {...confirmState} close={closeConfirm} action={action} /> : null;

  return [confirm, showConfirm];
};
