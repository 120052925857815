export const messagesMock = {
  count: 1683,
  next: 'http://sms.mybstr.com/api/messages?date_after=2023-10-24T14%3A03%3A15&date_before=2023-10-24T15%3A03%3A15&limit=10&offset=10&ordering=-created&phone__number=&type=1',
  previous: null,
  results: [
    {
      id: '6140acef-c7a3-4164-b430-f1c1a563c4cc',
      status: 'ACCEPTED',
      message_type: 2,
      body: 'Код подтверждения: 5177',
      created: '2023-10-24T15:02:18',
      operator: 'megafon',
      sender: '3065',
      phone_number: '79956227620',
    },
    {
      id: 'd51dd436-88cd-45b1-b30b-7bdfcd37f1f7',
      status: 'ACCEPTED',
      message_type: 2,
      body: 'Код подтверждения: 4048',
      created: '2023-10-24T15:02:18',
      operator: 'megafon',
      sender: '3065',
      phone_number: '79210997575',
    },
    {
      id: '03225b05-a0b7-4050-a3a0-110596d826f1',
      status: 'ACCEPTED',
      message_type: 2,
      body: 'Код подтверждения: 7734',
      created: '2023-10-24T15:02:16',
      operator: 'megafon',
      sender: '3065',
      phone_number: '79219196666',
    },
    {
      id: 'a1dd559a-1201-4823-a442-0a654e943e69',
      status: 'ACCEPTED',
      message_type: 2,
      body: 'Код подтверждения: 8195',
      created: '2023-10-24T15:02:11',
      operator: 'yota',
      sender: '3065',
      phone_number: '79999997525',
    },
    {
      id: '77a40178-d636-48a8-8636-1ccb10bc38c3',
      status: 'ACCEPTED',
      message_type: 2,
      body: 'Код подтверждения: 0364',
      created: '2023-10-24T15:02:06',
      operator: 'beeline',
      sender: '3065',
      phone_number: '79618021997',
    },
    {
      id: 'fc9b5393-9cb1-4196-8520-824174e75c01',
      status: 'ACCEPTED',
      message_type: 2,
      body: 'Код подтверждения: 3508',
      created: '2023-10-24T15:02:05',
      operator: 'mts',
      sender: '3065',
      phone_number: '79111826929',
    },
    {
      id: '075ed2ea-447e-44e5-8080-aa3890d5383f',
      status: 'ACCEPTED',
      message_type: 2,
      body: 'Код подтверждения: 3476',
      created: '2023-10-24T15:02:05',
      operator: 'megafon',
      sender: '3065',
      phone_number: '79217534224',
    },
    {
      id: '89c08518-72ce-4dfa-97bd-0a4f1c402686',
      status: 'ACCEPTED',
      message_type: 2,
      body: 'Код подтверждения: 2678',
      created: '2023-10-24T15:02:00',
      operator: 'beeline',
      sender: '3065',
      phone_number: '79214222418',
    },
    {
      id: '42346bfd-487b-46a8-ae7f-406988709c8b',
      status: 'ACCEPTED',
      message_type: 2,
      body: 'Код подтверждения: 7381',
      created: '2023-10-24T15:02:00',
      operator: 'tele2',
      sender: '3065',
      phone_number: '79643830505',
    },
    {
      id: 'f546e097-5bbb-4a15-9ee3-d4840a97ce46',
      status: 'ACCEPTED',
      message_type: 2,
      body: 'Код подтверждения: 6356',
      created: '2023-10-24T15:01:58',
      operator: 'megafon',
      sender: '3065',
      phone_number: '79315794424',
    },
  ],
  count_in: 327,
  count_out: 1319,
  count_error: 37,
};
