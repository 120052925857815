import { ListItem } from './list-item';
import { Content, Items, Title } from './list.styled';

export type ListProps = {
  items: Array<IListItem>;
  title?: string;
};

export const List = (props: ListProps) => {
  const { items, title } = props;

  const getItems = () => {
    const cnt = items.length;
    return items.map((el, idx) => {
      return <ListItem key={idx} item={el} last={idx === cnt - 1} />;
    });
  };

  const itemsEl = getItems();

  return (
    <Content>
      {title && <Title>{title}</Title>}
      <Items>{itemsEl}</Items>
    </Content>
  );
};

export default List;
