//import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FileName = styled.div`
  font-size: 14px;
  color: #999;
  line-height: normal;
  margin: 0 0 -15px;
  width: 290px;
  overflow: hidden;
  padding: 7px 0;
  position: relative;
  min-height: 50px;
`;
