import MUICheckbox, { CheckboxProps as MUICheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export type CheckboxProps = MUICheckboxProps & {
  label?: string;
};

export function Checkbox(props: CheckboxProps) {
  const { label, ...rest } = props;
  return <FormControlLabel control={<MUICheckbox {...rest} />} label={label} />;
}

Checkbox.defaultProps = {
  color: 'primary',
};

export default Checkbox;
