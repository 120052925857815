import { Content, Left, Right } from './list-item.styled';

export type ListItemProps = {
  item: IListItem;
  last?: boolean;
};

export const ListItem = (props: ListItemProps) => {
  const { item, last } = props;

  /**
   * get value
   * @returns
   */
  const getValue = () => {
    if (item.value) {
      return item.value;
    }

    return null;
  };

  const value = getValue();
  if (!value) {
    return null;
  }

  return (
    <Content $last={last}>
      <Left>{item.title}</Left>
      <Right>{value}</Right>
    </Content>
  );
};

ListItem.defaultProps = {
  last: false,
};

export default ListItem;
