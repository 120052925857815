//import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Content = styled.div`
  min-width: 500px;
  @media (max-width: 639px) {
    min-width: 290px;
  }
`;

export const Body = styled.div``;
