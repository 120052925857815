// import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';

import { Checkbox, CheckboxProps } from 'libs/ui';
import { Field } from '../field/field';

export const CheckboxField = (props: CheckboxProps & FormFieldProps) => {
  const { control, ...rest } = props;

  // render
  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message;
        const fieldProps: any = {
          checked: field.value,
        };
        if (fieldState.invalid) {
          fieldProps.error = fieldState.invalid;
          fieldProps.color = 'error';
          fieldProps.helperText = errorMessage;
        }
        const params = { ...field, ...rest, ...fieldProps };
        return <Field Cmp={Checkbox} params={params} />;
      }}
    />
  );
};

CheckboxField.defaultProps = {
  control: null,
};
