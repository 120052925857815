import { useStore } from 'app/store';
import { UserWidget } from 'app/ui';
import { Logo } from 'libs/layout';
import { MainMenu } from 'libs/ui';
import { links } from 'libs/data-config';
import { StyledAppBar, StyledToolbar, Left, Right } from './header.styled';

export const Header = () => {
  const admin = useStore((state) => state.admin);
  // console.log('🚀 ~ Header ~ admin:', admin);
  const userLogined = useStore((state) => state.userLogined);
  const items = [{ title: 'Сообщения', link: links.messages }];
  if (admin) {
    items.push({ title: 'Пользователи', link: links.users });
  }

  return (
    <StyledAppBar position="sticky">
      <StyledToolbar>
        <Left>
          <Logo />
          {userLogined && <MainMenu items={items} />}
        </Left>
        <Right>
          <UserWidget />
        </Right>
      </StyledToolbar>
    </StyledAppBar>
  );
};
