import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const BottomButtons = styled.div<{ center?: boolean; nopad?: boolean; withMargin?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 34px 0 0;
  padding: 0 15px;
  ${(props) =>
    props.center &&
    css`
      text-align: center;
      justify-content: center;
    `}
  ${(props) =>
    props.nopad &&
    css`
      padding: 0;
    `}

  ${(props) =>
    props.withMargin &&
    css`
      margin: 0 -8px;
      & > * {
        margin: 0 8px;
      }
    `}
`;
export const PrevButton = styled.button`
  background: linear-gradient(0deg, #6b7787, #6b7787), linear-gradient(0deg, #b7bcc2, #b7bcc2), linear-gradient(0deg, #b7bac4 0%, #c7cbd6 100%);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.4);
  border: 0;
  min-height: 80px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
  padding: 18px 40px 18px 50px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: -15px;
    top: 0;
    width: 15px;
    height: 100%;
    background: url('/images/prev.svg') no-repeat 0 0;
  }
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;
export const NextButton = styled(PrevButton)`
  background: #034ea2;
  padding: 18px 50px 18px 40px;
  &:before {
    left: auto;
    right: -15px;
    background: url('/images/next.svg') no-repeat 100% 0;
  }
`;

export const GrayButton = styled.button`
  background: linear-gradient(0deg, #6b7787, #6b7787), linear-gradient(0deg, #b7bcc2, #b7bcc2), linear-gradient(0deg, #b7bac4 0%, #c7cbd6 100%);
  border: 0;
  min-height: 80px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 30px;
  color: #fff;
  min-width: 150px;
  cursor: pointer;
  padding: 18px 40px 18px 40px;
  position: relative;
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.7;
    `}
`;

export const EmptyButton = styled.div`
  min-width: 150px;
  height: 80px;
`;
