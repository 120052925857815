import { useState } from 'react';

import { Button } from 'libs/ui';
import { Content, ButtonContent, Info } from './table-filter-actions.styled';

export const TableFilterActions = (props: TableFilterActionsProps) => {
  const { actions, filter, orderString } = props;
  const [loading, setLoading] = useState(false);

  const items = actions.map((el, idx) => {
    const { title, color, startIcon, info } = el;
    const action = () => {
      setLoading(true);
      el.action(filter, orderString).finally(() => setLoading(false));
    };

    const cl = loading ? 'loading' : '';

    return (
      <ButtonContent key={idx}>
        <Button
          fullWidth
          className={'filter-action-btn ' + cl}
          disabled={loading}
          type="button"
          color={color || 'inherit'}
          size="medium"
          onClick={action}
          startIcon={startIcon}
        >
          {title}
        </Button>
        {info && <Info>{info}</Info>}
      </ButtonContent>
    );
  });

  return <Content>{items}</Content>;
};
