import { create } from 'zustand';

const defaultUser: any = {};

type State = {
  loading: boolean;
  userLogined: boolean;
  user: any;
  admin: boolean;
};

/*
type Actions = {
  setLoading: (loading: boolean) => void;
  setChecked: (checked: boolean) => void;
  setUser: (user: any) => void;
  logout: () => void;
  unsetAuth: () => void;
};*/

export const useStore = create<State>(() => ({
  loading: false,
  userLogined: false,
  user: defaultUser,
  admin: false,
}));

export const setLoading = (loading: boolean) => useStore.setState({ loading: loading });
export const setChecked = (checked: boolean) => useStore.setState({ userLogined: checked });
export const setUser = (user: any) => useStore.setState({ user: user, userLogined: true, admin: user ? user.role === 'admin' : false });
export const logout = () => useStore.setState({ userLogined: false, admin: false });
export const unsetAuth = () => {
  useStore.setState({ userLogined: false, admin: false, user: defaultUser });
};
