import { Action } from './action';
import { Content } from './actions.styled';

export type ActionsProps = {
  items: Array<any>;
};

export const Actions = ({ items }: ActionsProps) => {
  const itemsEl = items.map((el, idx) => {
    return <Action key={idx} {...el} />;
  });

  return <Content>{itemsEl}</Content>;
};

export default Actions;
