import { configMock } from './config.mock';
import { loginMock } from './login.mock';
import { meMock } from './me.mock';
import { messagesMock } from './messages.mock';
import { chartMock } from './chart.mock';
export const mocks: Record<string, any> = {
  'api/messages/config': configMock,
  'api/auth/login': loginMock,
  'api/auth/me': meMock,
  'api/messages': messagesMock,
  'api/messages/chart': chartMock,
};
