import styled from '@emotion/styled';
import Button from '@mui/material/Button';

export const UserContent = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  margin-right: 15px;
  color: #fff;
  font-weight: 500;
`;

export const UserButton = styled(Button)`
  color: #fff;
  font-weight: 500;
  text-transform: none;
  .MuiButton-endIcon{
    margin-left: 15px;
  }
`;
