const errors: Record<string, string> = {
  'Forbidden: invalid password': 'Неверный пароль',
  'Invalid params: user not found': 'Пользователь не найден',
  'user with this login already exists.': 'Пользователь с таким логином уже существует',
  'Wrong old password': 'Неверный старый пароль',
  ERR_NETWORK: 'Сетевая ошибка',
  ECONNABORTED: 'Соединение прекращено',
  404: 'Страница не найдена',
  400: 'Ошибка данных',
  401: 'Неправильный логин или пароль',
  403: 'Нет прав для выполнения операции',
  405: 'Метод не разрешен',
  500: 'Ошибка сервера',
};

export const getApiError = (msg: string): string => {
  const out = errors[msg] ? errors[msg] : msg;
  return out;
};
