import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Place = styled.div`
  position: relative;
  min-width: 218px;
  @media (max-width: 639px) {
    /* min-width: 270px; */
  }
`;

export const FormError = styled.div<{ absoluteErrors?: boolean }>`
  margin: 1rem 0 0;
  width: 100%;
  text-align: left;
  p {
    font-size: 0.875rem;
    line-height: 1.36;
    color: #f00;
    margin: 0;
    padding: 0;
  }

  ${(props) =>
    props.absoluteErrors &&
    css`
      position: absolute;
      top: 100%;
      margin-top: 20px;
      left: 0;
      right: 0;
    `}
`;

export const FormGroup = styled.div<{ vertical: boolean }>`
  margin: 0 1rem 1rem 0;
  ${(props) =>
    props.vertical &&
    css`
      margin: 0 0rem 1rem 0;
    `}
`;
