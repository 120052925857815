import { StyledContainer, StyledContainerCentered, StyledContent, IContainerProps } from './container.styled';

type IContainer = IContainerProps & {
  vcenter: boolean;
};

export const Container = (props: IContainer) => {
  const { children, vcenter, ...rest } = props;
  const ContainerCmp = vcenter ? StyledContainerCentered : StyledContainer;

  return (
    <ContainerCmp {...rest}>
      <StyledContent>{children}</StyledContent>
    </ContainerCmp>
  );
};

Container.defaultProps = {
  vcenter: false,
  maxWidth: 'none',
  disableGutters: true,
};
