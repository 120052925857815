//import react from 'react';
import { Store } from 'react-notifications-component';
import dayjs, { Dayjs } from 'dayjs';

import { config } from 'app/config';

// moment.suppressDeprecationWarnings = true;

export function getRand(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const notify = (title: string, msg: string = '', other?: any) => {
  other = {
    ...{
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: { duration: 2000, onScreen: true },
    },
    ...other,
  };

  if (!title) {
    title = 'Error';
  }

  if (typeof msg !== 'string') {
    msg = JSON.stringify(msg);
  }

  if (!msg) {
    msg = 'Неизвестная ошибка';
  }

  const params = {
    ...other,
    title: title,
    message: msg,
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismissable: { click: true },
  };

  //console.log('notify', params);
  Store.addNotification(params);
};

export const fakeWait = (counts: number) => {
  let k;
  const cnt = counts * 100000;
  for (let i = 0; i < cnt; i++) {
    k = i ** 9;
  }
  return k;
};

/**
 *
 * @param time ms
 * @returns
 */
export const wait = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(time), time);
  });

export const getError = (code: number, type: string, message: string) => {
  if (message) {
    return message;
  }

  if (config.errors[type] && config.errors[type][code]) {
    return config.errors[type][code].ru;
  }

  return code;
};

/**
 * date time functions
 */
export const stringToDate = (time?: any, format = 'D MMMM YYYY') => {
  const out = dayjs(time);
  out.locale('ru');
  return out.format(format);
};

export const unixToDate = (time: number, format = 'D MMMM YYYY HH:mm:ss') => {
  const out = dayjs.unix(time);
  out.locale('ru');
  return out.format(format);
};

/**
 * get date for api
 * @param val
 * @param format
 */
export const getApiDate = (val: Dayjs, format = 'YYYY-MM-DDTHH:mm:ss') => {
  return val.format(format);
};

/**
 * get view date
 * @param val
 * @returns
 */
export const getViewDate = (val: any) => {
  const d1 = stringToDate(val, 'D MMM YYYY|HH:mm:ss').split('|');
  return (
    <div className="date-time">
      <b>{d1[0]}</b>
      {d1[1]}
    </div>
  );
};

/**
 * get valute
 * @param val
 * @returns
 */
export const getValute = (val: number) => {
  if (!val) {
    return '';
  }
  return val / 100 + ' ₽';
};

/**
 * download file by link
 * @param link
 * @param blank
 */
export const downloadFile = (link: string, blank = false) => {
  /*
  const blob = new Blob([data], { type: 'text/html' });
  */
  //link.href = window.URL.createObjectURL(blob);
  //let fn = link.split('/');
  //fn = fn[fn.length - 1];
  const el = window.document.createElement('a');
  el.href = link;
  el.download = 'download'; //fn;
  if (blank) {
    el.target = '_blank';
  }
  window.document.body.appendChild(el);
  el.click();
  window.document.body.removeChild(el);
};

/**
 * get user name
 * @param user
 * @returns
 */
export const getUserName = (user: UserMeResponse) => {
  if (!user) {
    return '';
  }
  const out = [];
  if (user.first_name) {
    out.push(user.first_name);
  }
  if (user.last_name) {
    out.push(user.last_name);
  }
  if (!out.length) {
    out.push(user.role === 'admin' ? 'Администратор' : 'Пользователь');
  }
  return out.join(' ');
};

/**
 * prepare filter
 * @param filter
 * @returns
 */
export const prepareFilter = (filter?: any): any => {
  const selectFields = ['status', 'message_type', 'operator'];
  const dateFields = ['date_after', 'date_before'];
  if (!filter) {
    return filter;
  }
  const out = { ...filter };

  // select -1
  selectFields.forEach((fn) => {
    if (out[fn] === -1) {
      delete out[fn];
    }
  });

  // date format
  dateFields.forEach((fn) => {
    if (out[fn]) {
      out[fn] = getApiDate(out[fn]);
    } else {
      delete out[fn];
    }
  });

  return out;
};

export const formatNumber = (val: number, decimal: number = 0) => {
  const valString = val.toFixed(decimal) + '';
  const x = valString.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? '.' + x[1] : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ' ' + '$2');
  }
  return x1 + x2;
};
