import { useState, useEffect } from 'react';

import { notify } from 'libs/common';
import { Loader } from 'libs/ui';
import { userService } from 'libs/services';
import Router from './router/router';

const App = () => {
  const [checked, setChecked] = useState(false);

  /**
   * check user token
   */
  useEffect(() => {
    const checkToken = async () => {
      await userService.checkToken().catch((error) => {
        notify('Ошибка сети', error.toString() );
      });
      setChecked(true);
    };
    checkToken();
  }, []);

  /**
   * checked ?
   */
  if (!checked) {
    return <Loader />;
  }

  return <Router />;
  // return <Loader />;
};

export default App;
