import FilterListIcon from '@mui/icons-material/FilterList';

import { Form, TextField, CheckboxField, DateTimeField, SelectField, ResetButton } from 'libs/form';
import { Button } from 'libs/ui';

import { Content, Actions, Item } from './table-filter.styled';

export const TableFilter = (props: TableFilterProps) => {
  /**
   * get component
   * @param type
   * @returns
   */
  const getCmp = (type: string) => {
    switch (type) {
      case 'checkbox':
        return CheckboxField;
      case 'datetime':
        return DateTimeField;
      case 'select':
        return SelectField;
      default:
        return TextField;
    }
  };

  // items
  const items = props.filters?.map((el, idx) => {
    const { type, name, title, options, ...rest } = el;
    const Cmp = getCmp(type);
    return <Cmp fullWidth key={idx} name={name} label={title} options={options} {...rest} container={Item} />;
  });

  return (
    <Content $vertical={props.vertical}>
      <Form defaultValues={props.initialFilter} onSubmit={props.onChange} hideLoading>
        {items}
        <Actions>
          <ResetButton fullWidth onSubmit={props.onChange} /> &nbsp;&nbsp;&nbsp;  
          <Button fullWidth type="submit" color="primary" size="medium" className="filter-btn" startIcon={<FilterListIcon />}>
            Ok
          </Button>
        </Actions>
      </Form>
    </Content>
  );
};
