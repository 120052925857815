/**
 * status values
 */
const statuses = ['ACCEPTED', 'PENDING', 'UNDELIVERABLE', 'DELIVERED', 'EXPIRED', 'REJECTED'];
const statusesLabels = ['Принято', 'В статусе ожидания', 'Не может быть доставлено', 'Доставлено', 'Срок жизни истек', 'Отклонено'];
const statusOptions: Array<IOption> = statuses.map((el, idx) => {
  return { title: statusesLabels[idx], value: el };
});
statusOptions.unshift({ title: 'Все', value: -1 });

/**
 * get status by id
 * @param val
 * @returns
 */
const getStatus = (val: string) => {
  const item = statuses.findIndex((el) => el === val);
  return item !== -1 ? statusesLabels[item] : '-';
};

/**
 * get row color
 * @param row
 * @returns
 */
const getRowColorByStatus = (row: any): string => {
  return row.status === 3 ? '#eeffee' : [4, 5].includes(row.status) ? '#ffeeee' : '';
};

/**
 * type values
 */
const types = [1, 2, 3];
const typesLabels = ['Входящие', 'Исходящие', 'Сообщения об ошибках'];
const typesOptions: Array<IOption> = types.map((el, idx) => {
  return { title: typesLabels[idx], value: el };
});
typesOptions.unshift({ title: 'Все', value: -1 });

/**
 * get type by id
 * @param val
 * @returns
 */
const getType = (val: number) => {
  const item = types.findIndex((el) => el === val);
  return item !== -1 ? typesLabels[item] : '-';
};

/**
 * roles values
 */
const roles = ['admin', 'worker'];
const rolesLabels = ['Администратор', 'Работник'];
const rolesOptions: Array<IOption> = roles.map((el, idx) => {
  return { title: rolesLabels[idx], value: el };
});
// rolesOptions.unshift({ title: 'Все', value: -1 });

/**
 * get role by id
 * @param val
 * @returns
 */
const getRole = (val: string) => {
  const item = roles.findIndex((el) => el === val);
  return item !== -1 ? rolesLabels[item] : '-';
};

export { statuses, statusOptions, getStatus, getRowColorByStatus, types, typesOptions, getType, roles, rolesOptions, getRole };
