import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { deepmerge } from 'deepmerge-ts';
// import dayjs from 'dayjs';

export type DateTimeInputProps = {} & DateTimePickerProps<any>;

export function DateTimeInput(props: DateTimeInputProps) {
  const other: DateTimeInputProps = {
    ampm: false,
    ampmInClock: false,
    slotProps: {
      field: { clearable: true },
      textField: { size: 'small' },
    },
    // timezone: 'default',
  };
  const out = deepmerge(other, props);
  return <DateTimePicker {...out} />;
}
